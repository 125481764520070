document.addEventListener('DOMContentLoaded', function () {
  const fileInputV1 = document.getElementById(
    'annual_report_ar_v1_q8_1_file_url'
  )
  const fileInputV3 = document.getElementById(
    'annual_report_ar_v3_q8_1_file_url'
  )

  const fileInput = fileInputV1 ? fileInputV1 : fileInputV3

  if (fileInput) {
    fileInput.addEventListener('click', function (event) {
      const div = document.getElementById('metrics-errors')
      if (div) {
        div.classList.add('visually-hidden')
      }
      this.value = null
    })

    fileInput.addEventListener('change', function (event) {
      if (event.target.files.length > 0) {
        const inProgressContainer = document.getElementById(
          'metrics-validation-in-progress'
        )

        inProgressContainer.classList.remove('visually-hidden')

        var csrfToken = document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content')

        var formData = new FormData()
        formData.append('file', event.target.files[0])

        var form = $(fileInput).closest('form')
        form.find(':submit').prop('disabled', true)

        var url =
          '/reporting-centre/accounts/' +
          document.getElementById('account_id').value +
          '/annual_reports/validate_metrics'

        fetch(url, {
          headers: {
            'X-CSRF-Token': csrfToken,
          },
          method: 'POST',
          body: formData,
          credentials: 'include',
        })
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            inProgressContainer.classList.add('visually-hidden')

            const errorContainer = document.getElementById(
              'metrics-errors-list'
            )
            const metricsError = document.getElementById('metrics-errors')
            const metricsValid = document.getElementById('metrics-valid')

            if (errorContainer) {
              errorContainer.innerHTML = ''

              form.find(':submit').prop('disabled', false)
              if (data.errors && data.errors.length > 0) {
                metricsError.classList.remove('visually-hidden')
                metricsValid.classList.add('visually-hidden')

                form.find(':submit').prop('disabled', true)
                data.errors.forEach((error) => {
                  const listItem = document.createElement('li')
                  listItem.textContent = error
                  errorContainer.appendChild(listItem)
                })
              } else {
                metricsError.classList.add('visually-hidden')
                metricsValid.classList.remove('visually-hidden')
              }
            }
          })
          .catch((error) => {
            console.error('Error during fetch:', error)
          })
      } else {
        console.log('No file selected')
      }
    })
  } else {
    console.log('File input element not found')
  }
})
